import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import hasteDevImg from "../haste-dev.png";
import { Leaderboard } from "haste-arcade-sdk";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
`;

const StyledIframe = styled.iframe`
  width: 60vw;
  height: 60vh;
  overflow: hidden;
  border: none;
  background-color: white;
`;

const Image = styled.img`
  width: 15rem;
  height: auto;
  margin: 2rem;
`;

const StyledText = styled.p`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  width: 60%;
`;

export const Integrate = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const src = urlParams.get("src") as string;
  const gameId = urlParams.get("gameId") as string;
  const [games] = useState([
    {
      id: gameId,
      src,
    },
  ]);
  const gameRefs = useRef(new Map());

  useEffect(() => {
    if (!games) return;
    const handleGameMessage = (event: MessageEvent) => {
      if (!src || !gameId)
        throw new Error(
          "Missing game source or id params. Please provide the source and gameId where your game is hosted using the 'src' query parameter. eg. https://dev.hastearcade.com/?gameId=dev&src=http://localhost:3000"
        );
      if (event.origin === window.location.origin) return;
      if (!games.some((game) => new URL(game.src).origin === event.origin)) {
        throw new Error(
          "Received message from invalid origin: " + event.origin
        );
      }
      if (!games.some((game) => event.data.gameId === game.id)) {
        throw new Error(
          "Received message from invalid game. Use gameId f8c22e6c-1086-4529-8800-2c72f98b9915 for the gameId in the url param while testing the demo game."
        );
      }
      // TODO: need to get the gameId from and isLowerScore from api
      const { type, score, isLowerScoreBetter } = event.data;
      switch (type) {
        case "play":
          console.log("Requesting for user to lock HST...");
          setTimeout(() => {
            sendMessageToGame(games[0].id, {
              type: "play",
              data: { playId: window.crypto.randomUUID() },
            });
          }, 2500);
          break;
        case "score":
          console.log(`Sending score: ${score}`);
          setTimeout(() => {
            sendMessageToGame(games[0].id, {
              type: "score",
              data: { playId: window.crypto.randomUUID() },
            });
          }, 2500);
          break;
        case "leaderboard":
          console.log("Getting leaderboard...");
          const staticAvatarUrl =
            "https://i.ibb.co/zGcthBv/yours-org-light.png";
          let leaderboard = [
            {
              txid: "sample-txid-from-blockchain-111",
              userId: window.crypto.randomUUID(),
              score: Math.floor(Math.random() * 100),
              displayName: "wags",
              avatarUrl: staticAvatarUrl,
            },
            {
              txid: "sample-txid-from-blockchain-222",
              userId: window.crypto.randomUUID(),
              score: Math.floor(Math.random() * 100),
              displayName: "joeyd",
              avatarUrl: staticAvatarUrl,
            },
            {
              txid: "sample-txid-from-blockchain-333",
              userId: window.crypto.randomUUID(),
              score: Math.floor(Math.random() * 100),
              displayName: "foundrium",
              avatarUrl: staticAvatarUrl,
            },
            {
              txid: "sample-txid-from-blockchain-444",
              userId: window.crypto.randomUUID(),
              score: Math.floor(Math.random() * 100),
              displayName: "rallieon",
              avatarUrl: staticAvatarUrl,
            },
          ] as Leaderboard[];

          if (isLowerScoreBetter) {
            leaderboard.sort((a, b) => a.score - b.score);
          }
          setTimeout(() => {
            sendMessageToGame(games[0].id, {
              type: "leaderboard",
              data: { leaderboard },
            });
          }, 1000);
          break;
        case "transferHst":
          console.log("Transfering HST...");
          setTimeout(() => {
            sendMessageToGame(games[0].id, {
              type: "transferHst",
              data: {
                txid: "d0eb927a13916ea83ff7cdf8c51a4d52f141084e1609813617f8940ebe6c1297",
              },
            });
          }, 1000);
          break;
        default:
          console.log("Unhandled message type:", type);
      }
    };

    window.addEventListener("message", handleGameMessage);
    return () => window.removeEventListener("message", handleGameMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games]);

  const sendMessageToGame = (gameId: string, message: any) => {
    const gameIframe = gameRefs.current.get(gameId);
    if (gameIframe) {
      gameIframe.contentWindow.postMessage(
        message,
        new URL(gameIframe.src).origin
      );
    }
  };

  return (
    <Container>
      <Image src={hasteDevImg} alt="logo" />
      <StyledText>
        Welcome to the Haste Arcade demo application. Check out the{" "}
        <a
          style={{ color: "#ffbf00" }}
          href="https://docs.hastearcade.com"
          target="_blank"
          rel="noreferrer"
        >
          SDK docs
        </a>{" "}
        to get started. When you're finished, you should be able to play your
        game in the iFrame below.
      </StyledText>
      {games.map((game) => (
        <div key={game.id}>
          <StyledIframe
            ref={(el) => gameRefs.current.set(game.id, el)}
            title={game.id}
            src={game.src}
          />
        </div>
      ))}
    </Container>
  );
};
