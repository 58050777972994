import axios from "axios";
import { buildAuthHeaders } from "../buildAuthHeaders";
import { Addresses } from "panda-wallet-provider";

export type PostCreateUserResponse = {
  id: string;
};

export const postCreateUser = async (
  addresses: Addresses
): Promise<PostCreateUserResponse | undefined> => {
  try {
    const endpoint = `user/create`;
    const authHeaders = buildAuthHeaders(addresses);
    const baseUrl = process.env.REACT_APP_API_BASE_URL as string;
    const res = await axios.post<PostCreateUserResponse>(
      `${baseUrl}/${endpoint}`,
      {},
      authHeaders
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
