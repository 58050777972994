import styled from "styled-components";
import hasteDevImg from "../haste-dev.png";
import { Addresses, usePandaWallet } from "panda-wallet-provider";
import { postCreateUser } from "../api/user/postCreateUser";
import { useState } from "react";
import { postCreateApiKey } from "../api/user/postCreateApiKey";
import { Show } from "../components/Show";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
`;

const Image = styled.img`
  width: 15rem;
  height: auto;
  margin: 2rem;
`;

const StyledText = styled.p`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  width: 60%;
`;

const ApiKeyText = styled(StyledText)`
  color: gold;
`;

const Button = styled.button`
  background-color: #f5f5f5;
  color: black;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
`;

export const ApiKey = () => {
  const wallet = usePandaWallet();
  const [userId, setUserId] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const handleConnectWallet = async () => {
    const isReady = wallet?.isReady;
    if (!isReady) {
      window.open(
        "https://chromewebstore.google.com/detail/panda-wallet/mlbnicldlpdimbjdcncnklfempedeipj",
        "_blank"
      );
      return;
    }
    setProcessing(true);
    const identityPubKey = await wallet.connect();

    if (identityPubKey) {
      const addresses = await wallet.getAddresses();
      if (addresses?.identityAddress) {
        setAddresses(addresses);
        const res = await postCreateUser(addresses);
        if (res?.id) setUserId(res.id);
      }
    }
    setProcessing(false);
  };

  const handleGenerateApiKey = async () => {
    if (!userId || !addresses) return;
    setProcessing(true);
    const res = await postCreateApiKey(addresses);
    if (res?.apiKey) setApiKey(res.apiKey);
    setProcessing(false);
  };
  return (
    <Container>
      <Image src={hasteDevImg} alt="logo" />
      <StyledText>
        {apiKey
          ? "Congrats, your ready to start building. Do not share your api key and store it somewhere safely. You can now return to the SDK docs and register your game."
          : userId
          ? "Generate your API key"
          : "Before generating an API key, you need to connect your wallet."}
      </StyledText>
      <Show when={!apiKey} whenFalseContent={<ApiKeyText>{apiKey}</ApiKeyText>}>
        <Show
          when={!processing}
          whenFalseContent={<Button disabled>Processing...</Button>}
        >
          <Button onClick={userId ? handleGenerateApiKey : handleConnectWallet}>
            {userId ? "Generate API Key" : "Connect Wallet"}
          </Button>
        </Show>
      </Show>
    </Container>
  );
};
