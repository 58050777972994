import { Addresses } from "panda-wallet-provider";

export const buildAuthHeaders = (addresses: Addresses) => {
  const { identityAddress, bsvAddress, ordAddress } = addresses;
  return {
    headers: {
      "identity-address": identityAddress,
      "bsv-address": bsvAddress,
      "ord-address": ordAddress,
    },
  };
};
